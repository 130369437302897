<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(onSubmit)">
      <v-row>
        <v-col class="pa-0 mx-0" cols="12" sm="6">
          <v-stepper alt-labels class="elevation-0" :value="2">
            <v-stepper-header>
              <v-stepper-step step="1">
                Select Date
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="2">
                Your Details
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="✔">
                Confirmation
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12" sm="6" class="pt-0">
          <h1>Last step - your details</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span class="subheading">Name</span>
        </v-col>
      </v-row>
      <v-row class="mb-0">
        <v-col cols="6" sm="3" class="py-0">
          <text-input
              rules="required"
              label="First name"
              name="First name"
              v-model="firstName"
              vid="first_name"
          />
        </v-col>
        <v-col cols="6" sm="3" class="py-0">
          <text-input
              rules="required"
              label="Last name"
              name="Last name"
              v-model="lastName"
              vid="last_name"
          />
        </v-col>
      </v-row>
      <v-row class="my-0">
        <v-col cols="12" sm="6" class="py-0">
          <phone-number-input-component
              rules="required"
              label="Mobile phone"
              name="Mobile phone"
              v-model="phone"
              outlined
              @errorValue="getErrorValue"
              vid="phone"
          />
        </v-col>
      </v-row>
      <v-row class="my-0">
        <v-col cols="12" sm="6" class="py-0">
          <text-input
              rules="required|email"
              label="Email"
              name="Email"
              vid="email"
              v-model="email"
              @blur="searchByEmail"
          />
        </v-col>
      </v-row>
      <v-row class="my-0" v-if="existingAccount">
        <v-col cols="12" sm="6" class="py-0">
          <v-btn color="success" class="float-right" @click="redirectLogin()">
            Login
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pt-0">
          <h4>Booking Details:</h4>
          <b>Date:</b> {{ date | toShortDate }}<br/>
          <b>Location:</b> {{ property.name }}<br/>
          <!-- <b>Price:</b> <v-progress-circular v-if="quote === null" indeterminate size="12" width="2"/><span v-else>{{ quote | toCurrency }} <v-chip color="success" outlined small label>INCLUDES DISCOUNT</v-chip></span><br/> -->
        </v-col>
      </v-row>
      <v-row class="my-0" v-if="property.daycare_terms !== null">
        <v-col class="py-0">
          <ValidationProvider tag="div" rules="isChecked" name="Terms and conditions" v-slot="{ errors }">
          <v-checkbox
              v-model="terms"
              :error-messages="errors"
          >
            <template v-slot:label>
              <div>
                I agree to the
                    <a
                        target="_blank"
                        :href="property.daycare_terms"
                        @click.stop>
                      Terms and Conditions
                    </a>
              </div>
            </template>
          </v-checkbox>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col cols="12" sm="6">
          <v-btn color="success" block
                 :disabled="!canSubmit || existingAccount || error"
                 type="submit"
                 :loading="loadingCreate"
          >
            Book {{ petName }}'s first day
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" class="pt-0">
          <v-btn text block @click="$router.push({name: 'OnboardingStep1', params: {propertySlug: property.urlSlug}})">
          <v-icon>mdi-chevron-left></v-icon> Change Date
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="6" align="center">
          Questions? <a :href="`tel:${property.phone}`">Call: {{ property.phone }}</a>
        </v-col>
      </v-row>
      <img v-if="property.facebook_tracking_pixel !== null" :src="`https://www.facebook.com/tr?id=${property.facebook_tracking_pixel}&ev=PageView`" height="1" width="1" style="display:none"/>
    </form>
  </ValidationObserver>
</template>

<script>
import store from "@/store";

import TextInput from "@/components/TextInputComponent";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import PhoneNumberInputComponent from "../../components/PhoneNumberInputComponent.vue";
var moment = require('moment');
import {mapActions, mapGetters} from "vuex";
import CustomersRepository from "@/api/CustomersRepository";
export default {
  name: 'BookTrialDayDetails',
  title: 'Book a trial day | Your details',
  components: {
    TextInput,
    ValidationObserver,
    ValidationProvider,
    PhoneNumberInputComponent,
  },
  async beforeRouteEnter(to, from, next) {
    //Check step 1 is complete
    if(store.getters["newCustomerOnboarding/step1IsValid"]) {
      next();
    }
    else {
      next({name: 'OnboardingStep1', params: {propertySlug: to.params.propertySlug}})
    }
  },
  async beforeRouteUpdate(to, from, next) {
    //Check step 1 is complete
    if(store.getters["newCustomerOnboarding/step1IsValid"]) {
      next();
    }
    else {
      next({name: 'OnboardingStep1', params: {propertySlug: to.params.propertySlug}})
    }
  },
  mounted () {
    this.getQuote();
  },
  data: () => ({
    terms: false,
    error: false,
    existingAccount: false
  }),
  computed: {
    ...mapGetters({
      property: 'newCustomerOnboarding/property',
      petName: 'newCustomerOnboarding/petName',
      date: 'newCustomerOnboarding/date',
      quote: 'newCustomerOnboarding/quote',
      canSubmit: 'newCustomerOnboarding/step2IsValid',
      loadingCreate: 'newCustomerOnboarding/loading_create',
    }),
    tomorrow() {
      return moment().add(1, 'day').format('Y-MM-DD')
    },
    firstName: {
      get () {
        return this.$store.state.newCustomerOnboarding.firstName;
      },
      set (value) {
        this.$store.commit('newCustomerOnboarding/SET_FIRST_NAME', value);
      }
    },
    lastName: {
      get () {
        return this.$store.state.newCustomerOnboarding.lastName;
      },
      set (value) {
        this.$store.commit('newCustomerOnboarding/SET_LAST_NAME', value);
      }
    },
    phone: {
      get () {
        return this.$store.state.newCustomerOnboarding.phone;
      },
      set (value) {
        this.$store.commit('newCustomerOnboarding/SET_PHONE', value);
      }
    },
    email: {
      get () {
        return this.$store.state.newCustomerOnboarding.email;
      },
      set (value) {
        this.$store.commit('newCustomerOnboarding/SET_EMAIL', value);
      }
    },
  },
  methods: {
    ...mapActions({
      getQuote: 'newCustomerOnboarding/getQuote',
      createReservation: 'newCustomerOnboarding/createReservation',
      reset: 'newCustomerOnboarding/reset',
    }),
    getAllowedDates (val) {
      if (this.availability.indexOf(val) !== -1) {
        return true
      } else {
        return false
      }
    },
    getErrorValue(error) {
      this.error = error
    },
    async onSubmit () {
      try {
        let response = await this.createReservation()
        if (response.status === 200) {
          await this.$router.push({name: 'OnboardingConfirmation', params: {id: response.data.id}, query: {success: 'true'}});
          this.reset();
        }
      }
      catch (error) {
        console.log(error.response.data.errors)
        this.$refs.form.setErrors(error.response.data.errors);
      }
    },
    redirectLogin() {
      const routeData = this.$router.resolve('/login');
      window.open(routeData.href, '_blank');
    },
    async searchByEmail() {
      if (this.email) {
        this.loading = true;
        let response = await CustomersRepository.searchByEmailOnboarding({email: this.email});
        this.loading = false;
        if (Object.keys(response).length > 0) {
          this.existingAccount = true;
          this.$refs.form.setErrors({
            email: 'An existing account exists for this email address. Please login to finish the booking.'
          });
        } else {
          this.existingAccount = false;
        }
      }
    },
  },
}
</script>