<template>
  <div>
    <v-row>
      <v-col class="pa-0 mx-0" cols="12" sm="6">
        <v-stepper alt-labels class="elevation-0" :value="1">
          <v-stepper-header>
            <v-stepper-step step="1">
              Select Date
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="2">
              Your Details
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step step="✔">
              Confirmation
            </v-stepper-step>
          </v-stepper-header>
        </v-stepper>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12" sm="6" class="pt-0">
        <h1>Book your trial day at {{ property.name }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" class="py-0">
        <span class="subheading">Who will be joining us?</span>
        <v-text-field
            class="pt-2"
            v-model="petName"
            placeholder="Pet name"
            outlined
        />
      </v-col>
    </v-row>
    <v-row class="my-0">
      <v-col cols="12" sm="6">
        <span class="subheading text-left">What date would you like to attend?</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" class="py-0" align="center">

        <v-date-picker outlined
                       v-model="date"
                       width="100%"
                       style="max-width: 350px"
                       no-title
                       :allowed-dates="getAllowedDates"
        ></v-date-picker>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6">
        <v-btn color="success" block
               :disabled="!canSubmit"
               @click="$router.push({name: 'OnboardingStep2', params: {propertySlug: $route.params.propertySlug}})"
        >
          Next
        </v-btn>
      </v-col>
    </v-row>
    <img v-if="property.facebook_tracking_pixel !== null" :src="`https://www.facebook.com/tr?id=${property.facebook_tracking_pixel}&ev=PageView`" height="1" width="1" style="display:none"/>
  </div>
</template>

<script>
import store from "@/store";
import {mapGetters} from "vuex";

export default {
  name: 'BookTrialDaySelectDate',
  title: 'Book a trial day | Select date',
  props: ['source'],
  components: {

  },
  async beforeRouteEnter(to, from, next) {
      await store.dispatch("newCustomerOnboarding/loadAvailability", to.params.propertySlug)
      next();
  },
  async beforeRouteUpdate(to, from, next) {
    await store.dispatch("newCustomerOnboarding/loadAvailability", to.params.propertySlug)
    next();
  },
  data: () => ({
  }),
  mounted() {
    //Set the source if it has not been already set
    if(this.sourcePersisted === null && this.source !== null) {
      this.$store.commit('newCustomerOnboarding/SET_SOURCE', this.source);
    }
  },
  computed: {
    ...mapGetters({
      property: 'newCustomerOnboarding/property',
      availability: 'newCustomerOnboarding/availability',
      canSubmit: 'newCustomerOnboarding/step1IsValid',
      sourcePersisted: 'newCustomerOnboarding/source',
    }),
    canSubmit ()  {
      return this.petName !== null && this.petName.length > 0 && this.date !== null
    },
    petName: {
      get () {
        return this.$store.state.newCustomerOnboarding.petName;
      },
      set (value) {
        this.$store.commit('newCustomerOnboarding/SET_PET_NAME', value);
      }
    },
    date: {
      get () {
        return this.$store.state.newCustomerOnboarding.date;
      },
      set (value) {
        this.$store.commit('newCustomerOnboarding/SET_DATE', value);
      }
    }
  },
  methods: {
    getAllowedDates (val) {
      if (this.availability.indexOf(val) !== -1) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>
