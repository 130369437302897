<template>
  <div>
  <v-row>
    <v-col cols="12" sm="6">
      <span class="subheading">Make a recurring reservation</span>
      <v-chip-group
          v-model="frequency"
          active-class="accent--text text--accent-4"
          mandatory
          column
      >
        <v-chip small value="custom">Custom Dates</v-chip>
        <v-chip small value="1">Every Week</v-chip>
        <v-chip small value="2">Every 2 Weeks</v-chip>
        <v-chip small value="4">Every 4 Weeks</v-chip>
      </v-chip-group>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" sm="6" v-if="frequency !== 'custom'">
      <span class="subheading">Select Days</span>
      <v-chip-group
          v-model="days"
          active-class="accent--text text--accent-4"
          column
          multiple
      >
        <v-chip small value="monday">Mo</v-chip>
        <v-chip small value="tuesday">Tu</v-chip>
        <v-chip small value="wednesday">We</v-chip>
        <v-chip small value="thursday">Th</v-chip>
        <v-chip small value="friday">Fr</v-chip>
        <v-chip small value="saturday">Sa</v-chip>
        <v-chip small value="sunday">Su</v-chip>
      </v-chip-group>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" sm="6" v-if="frequency !== 'custom'">
      <span class="subheading">Last date</span>
      <date-picker outlined
                   :min="min"
                   v-model="finalDate"
      ></date-picker>
    </v-col>
  </v-row>
  </div>
</template>

<script>
import DatePicker from "@/components/DatePickerComponent.vue";
var moment = require('moment');

export default {
  components: {
    DatePicker,
  },
  props: {
    min: [String, Date],
  },
  data: () => ({
    frequency: 0,
    days: [],
    finalDate: null,
  }),
  computed: {
    payload() {
      return {
        isValid: this.isValid(),
        data: {
          frequency: this.frequency,
          days: this.frequency !== 'custom' ? this.days: null,
          end: this.frequency !== 'custom' ? moment(this.finalDate).format('Y-MM-DD') : null,
        }
      }
    },
  },
  methods: {
    isValid () {
      if(this.frequency === 'custom') {
        return true;
      }
      else if(this.days.length === 0 || this.finalDate === null) {
        return false;
      }
      else {
        return true;
      }

    }
  },
  watch: {
    payload (val) {
      this.$emit('input', val);
    },
  },
}
</script>
